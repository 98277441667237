import { createApp } from "vue"
import App from "./App.vue"
import router from "./router"
import ElementPlus, { ElMessage } from "element-plus"
import "element-plus/dist/index.css"
import "reset.css"
// 粒子特效
import VueParticles from 'vue-particles'

import "default-passive-events";
import VueClipboard from 'vue3-clipboard'
// 自定义拖拽
import { initVDrag } from "@/utils/vDrag"
// 自定义缩放
import { initVWheelScale } from "@/utils/vWheelScale.js"
import DataVVue3 from '@kjgl77/datav-vue3'

import pinia from '@/store/store'
const app = createApp(App)
initVDrag(app)
initVWheelScale(app)


// 全局守卫
router.beforeEach((to, from, next) => {
    if (to.name === 'alarmform' || to.name === 'privacy' ||to.name === 'agreement' || to.name === 'empowerlogin' || to.name === 'commuting' || to.name === 'empowerregister') {
        next()
        return
    }
    // 获取登陆状态
    let isLogin = window.localStorage.getItem('token')  //存储的sessionStorage的信息，登陆时使用sessionStorage.setItem('sid', '已登录')  设置本地存储信息
    if (to.name === 'login') { // 如果是登录页，则跳过验证
        window.localStorage.clear()
        next()  //钩子函数
        return
    }
    if (!isLogin) { //  判断登陆状态,sessionStorage不存在的情况下
        next({
            name: 'login'
        }) // 如果未登录，则跳转到登录页
        ElMessage.error('请先登录系统')
    } else {
        next() // 如果已经登陆，那就可以跳转
    }
})
window.ResizeObserver = class _NewResizeObserver extends ResizeObserver {
    constructor(callback) {
        super(() => window.requestAnimationFrame(() => callback.apply(this, arguments)));
    }
}

app.use(pinia).use(router).use(ElementPlus).use(DataVVue3).use(VueParticles).use(VueClipboard, {
    autoSetContainer: true,
    appendToBody: true,
}).mount("#app")


